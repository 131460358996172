import React from 'react'
import './style.scss'
import { Link } from 'gatsby'


function CustomBlock({ fields: field }) {
    

	return (
        <div className="box grid-12 span-12-tablet">
            <div className="button-container span-12 ">
                <a href="https://portal.mru.edu/Applicant/InfoRequest.aspx" target="_blank" rel="noreferrer"><span className="button">Apply now</span></a>
                <Link to="/directory"><span className="button">Meet our Faculty</span></Link>
            </div> 
        </div>
	)
}

export default CustomBlock