import React from 'react'
import './style.scss'
import Img from 'gatsby-image'
import { useInView } from 'react-intersection-observer'
import Intro from '../../../../../animations/Intro'
import { Link } from 'gatsby'

function CustomBlock({ fields: field, images: image, imagesMobile: imageMobile }) {

	const [refInView, ioInView] = useInView({ triggerOnce: true })

  return (
    <>
    <div className='content-top'>
    <div ref={refInView} className='flex-12 x1 t'>
      <div className='left'>
      <Intro visible={ioInView} in={{ fadeLeft: 700 }} delayIn={500} mounted={true} stay={true} className="full-width">
        <div style={{ paddingBottom: "100%" }} className="aspect-ratio">
          <Img fluid={image?.[0]}
            imgStyle={{ objectFit: 'cover' }}
            objectPosition='50% 50%'
            className='Hero Image'
          />
        </div>
        </Intro>
      </div>
      <div className='right'>
        <Intro visible={ioInView} in={{ fadeRight: 700 }} delayIn={625} mounted={true} stay={true} className="full-width">
          <div style={{ paddingBottom: "50%" }} className="aspect-ratio logo-next-desktop">
            <Img fluid={image?.[1]}
            imgStyle={{ objectFit: 'contain' }}
            objectPosition='50% 50%'
            className='Hero Text'
          />
          </div>
          <div style={{ paddingBottom: "24%" }} className="aspect-ratio logo-next-mobile">
            <Img fluid={imageMobile?.[0]}
            imgStyle={{ objectFit: 'contain' }}
            objectPosition='50% 50%'
            className='Hero Text'
            />
           </div>
        </Intro>
        <Intro visible={ioInView} in={{ fadeBottom: 700 }} delayIn={750} mounted={true} stay={true} className="">
          <div className='text-bar'>
            <h1 className='h2 italic'>
              {field?.[0]}
            </h1>
          </div>
        </Intro>

      </div>
    </div>
    </div>
    <div id="block-custom-home-detail-new" className="block block-custom custom-home-detail-new">
    	<div className="master-wrap x1 t">
			<div className="intro fs-85"><h6>Choose a school:</h6></div>
      <div className='schools-wrap'>
			{ image[0] ?
				<Link className="wrap" to='/education'>
          <Intro visible={ioInView} in={{ fadeBottom: 500 }} delayIn={125} mounted={true} stay={true} className="full-width">
            <div className="aspect-ratio">
              <Img fluid={image[2]}
                imgStyle={{objectFit: 'cover'}}
                objectPosition='50% 50%'
                className='img1'
              />
              <div className="field">
                <h3 className="text h4">Education</h3>
                <div className="icon"></div>
              </div>
            </div>
          </Intro>
			  </Link>
			: null }
			{ image[1] ?
				<Link className="wrap" to='/business'>
         <Intro visible={ioInView} in={{ fadeBottom: 500 }} delayIn={250} mounted={true} stay={true} className="full-width">
            <div className="aspect-ratio">
              <Img fluid={image[3]}
                imgStyle={{objectFit: 'cover'}}
                objectPosition='50% 50%'
                className='img1'
              />
              <div className="field">
                <h3 className="text h4">Business</h3>
                <div className="icon"></div>
              </div>
            </div>
          </Intro>
				</Link>
			: null }
			{ image[2] ?
				<Link className="wrap test" to='/the-school-of-arts-sciences'>
          <Intro visible={ioInView} in={{ fadeBottom: 500 }} delayIn={375} mounted={true} stay={true} className="full-width">
            <div className="aspect-ratio">
              <Img fluid={image[4]}
                imgStyle={{objectFit: 'cover'}}
                objectPosition='50% 50%'
                className='img1'
              />
              <div className="field">
                <h3 className="text h4">The School of Arts & Sciences</h3>
                <div className="icon"></div>
              </div>
            </div>
          </Intro>
				</Link>
			: null }
			{ image[3] ?
				<Link className="wrap" to='/nursing'>
          <Intro visible={ioInView} in={{ fadeBottom: 500 }} delayIn={500} mounted={true} stay={true} className="full-width">
            <div className="aspect-ratio">
              <Img fluid={image[5]}
                imgStyle={{objectFit: 'cover'}}
                objectPosition='50% 50%'
                className='img1'
              />
              <div className="field">
                <h3 className="text h4">Nursing</h3>
                <div className="icon"></div>
              </div>
            </div>
          </Intro>
				</Link>
			: null }
      </div>
		</div>
    </div>
    </>
  )
}

export default CustomBlock