import React from 'react'
import './style.scss'

import { useInView } from 'react-intersection-observer'

import ACFBlocks from "../ACF"

function Section(props) {
	const [io, ioInView] = useInView({ triggerOnce: true })

	// Main Settings
	const anchor = props.anchor
	const classes = props.classes

	return (
		<section ref={io} id={anchor ? 'section-' + anchor : null} className={`content is-inview ${ioInView ? 'inview' : ''} ${classes ? classes : ''}`}>
			
			{anchor ?
				<div id={anchor} className="anchor"></div>
			: null}

			<ACFBlocks { ...props } />

			{ props.i === 0 ?
				<>
					{/* Point of reference for past hero observer threashold, so we can calculate if the user is past hero or not */}
					<div id="header-fold-breakpoint"></div>
				</>
			: null }

		</section>
	)
}

export default Section