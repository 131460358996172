import React from 'react'
import './style.scss'

import Section from "./Section"

// random.ts
function randomId(){

	if (typeof window !== 'undefined') {
		const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0]
		return uint32.toString(16)
	}
}

function Content(props) {

	let sections = null

	if (props.wordpressPage) {
		sections = props.wordpressPage.acf.sections_page
	} else if (props.fieldGroupName === 'pageBuilder') {
		sections = props.sections
	}
	
	// Map through acf flexible content sections
	const sectionsMap = sections?.map((node, i) => {

		const random_id = randomId()

		// Spit it out only if typename is not registered (only one in graphql), or when it's specifically content type (default in our setup)
		if(!node.__typename || node.__typename === 'WordPressAcf_content' || node.__typename === 'Academic_Pagebuilder_Sections_Content') {
			
			return (
				<Section key={`section-${node.id ? node.id : random_id}`} {...node} i={i}/>
			)
		}

		return null

	})

  return sectionsMap
}

export default Content