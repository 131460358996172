import React, { useState } from 'react'
import './style.scss'

import { useForm } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'


function CF7Club(props) {

	
	const { register, handleSubmit, errors, reset } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)

	// Form activation // thanks
	const [isActive, setActive] = useState(false)
	const [isSent, setSent] = useState(false)


	const onSubmit = (form, e) => {
		e.preventDefault()
		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
		data.append('your-fname', form.fname)
    data.append('your-lname', form.lname)
		data.append('your-email', form.email)
    data.append('your-club', form.club)

		
		const url = 'https://cms.mru.edu/wp-json/contact-form-7/v1/contact-forms/2106/feedback'
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
				console.log(data)
					console.log('status', response.data.status)
					console.log('response', response.data)
					if(response.data.message) {
					 setMessage(response.data.message)
					}
					setLoading(false)
					if(response.data.status === 'mail_sent') {
						setSent(true)
						setActive(false)
						reset()
					}
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}

	return (
		<>
			<div className="badge">
				<div className={`hi ${!isActive && !isSent ? 'active' : ''}`}></div>
				<div className={`thanks ${isSent && !isActive ? 'active' : ''}`}></div>
			</div>

			<div className="form-cut-off">
				<form
					name="contact"
					method="post"
          id="club-form"
					action="/thanks/"
					onSubmit={handleSubmit(onSubmit)}
					className={`grid-12  catering ${isActive ? 'active' : ''}`}
				>

					<div className={`span-12 grid-12 form-fields-wrap ${isActive ? 'active' : ''}`}>
            <p className="span-12 description">Start a Club - Academic Organization, Cultural or Religious Organization, Special Interest and Hobby Club, Support Groups.</p>
						<label  className="span-6 font4 input animated delay-500">
							<input  type="text" className="text-left" placeholder="FIRST NAME" name="fname" ref={register({required: false, maxLength: 80, message: "error message"})} />
							{errors.fname && <p className="small margin-off">your first name is required.</p>}
						</label>
                        <label className="span-6 font4 input animated delay-500">
							<input type="text" className="text-left" placeholder="LAST NAME" name="lname" ref={register({required: false, maxLength: 80, message: "error message"})} />
							{errors.lname && <p className="small margin-off">your last name is required.</p>}
						</label>
						<label className="span-6 font4 input animated delay-500">
							<input type="text" className="text-left" placeholder="EMAIL" name="email" ref={register({required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
							{errors.email && <p className="small margin-off">a correct email is required.</p>}
						</label>
						<label className="span-6 font4 input animated delay-500">
							<input type="text" className="text-left" placeholder="CLUB" name="club" ref={register({required: false, message: "error message"})} />
						</label>
	
						<div className="btn-wrap text-center animated delay-500">
							<button type="submit">SUBMIT {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
						</div>

						<div className="response-wrap text-center span-12 animated delay-500">
							<p className="message small">{message}</p>
						</div>
					</div>

				</form>
			</div>
		</>
	)
}

export default CF7Club