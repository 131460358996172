import React, { useEffect, useState } from 'react'
import './style.scss'
import Img from 'gatsby-image'


function CustomBlock(props) {

  const [currentSlide, setCurrentSlide] = useState(0)

  const singleItem = []
  const years = []

  useEffect(() => {

    let allSlides = document.querySelectorAll('.slide')
    let allMilestones = document.querySelectorAll('.milestone')

    allSlides.forEach((slide) => {
      if (slide.classList.contains('current'))
        slide.classList.remove('current')
    })
    allMilestones.forEach((slide) => {
      if (slide.classList.contains('current'))
        slide.classList.remove('current')
    })

    allSlides[currentSlide].classList.add('current')
    allMilestones[currentSlide].classList.add('current')

  }, [currentSlide])


  for (let i = 0; i < props.fields.length; i++) {

    let year = props.fields[i]
    let wysiwyg = props.wysiwygs[i]
    let image = props.images[i]


    singleItem.push(
      <div key={i} onClick={() => setCurrentSlide(i)} className={`slide slide-${currentSlide} single-item`}>
        <p className="year">
          {year}
        </p>

        <div className="content" dangerouslySetInnerHTML={{ __html: wysiwyg }} />
        <Img fluid={image}
          imgStyle={{ objectFit: 'cover' }}
          objectPosition='50% 50%'
          className="image"
        />
      </div>
    )

    years.push(
      <div key={i} className={i % 2 !== 0 ? "milestone top" : "milestone bottom"} onClick={() => setCurrentSlide(i)}>
        {year}
      </div>
    )
  }


  return (
    <div className="master-wrap">
      <div className='slider'>
        {singleItem}
      </div>
      <div className="milestones">
        <span onClick={() => setCurrentSlide(slide => currentSlide > 0 ? slide - 1 : currentSlide)} className={`btn-arrow prev ${currentSlide > 0 ? '' : 'disabled'}`}></span>
        {years}
        <div className='line'></div>
        <span onClick={() => setCurrentSlide(slide => currentSlide + 1 < years.length ? slide + 1 : currentSlide)} className={`btn-arrow next ${currentSlide + 1 < years.length ? '' : 'disabled'}`}></span>
      </div>
    </div>
  )
}

export default CustomBlock