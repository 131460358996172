import React from 'react'
import './style.scss'
import Img from "gatsby-image"
import { Link } from 'gatsby'
import Intro from '../../../../../animations/Intro'
import { useInView } from 'react-intersection-observer'
import FormDetail from '../FormDetail'

function CustomBlock({ images: image, wysiwygs: wysiwyg }) {

  const [refFormInView, ioFormInView] = useInView({ triggerOnce: true })
  const [refAboutInView, ioAboutInView] = useInView({ triggerOnce: true })
  const [refExploreInView, ioExploreInView] = useInView({ triggerOnce: true })

  return (
    <div className='custom-next-landing c0 grid-12'>

      <div className='span-5 flex span-12-mobile'>
        <div ref={refAboutInView}  className='padd-2 c3'>
          <Intro visible={ioAboutInView} in={{ fadeLeft: 700 }} delayIn={600} mounted={true} stay={true} className="of-visible">
            <div className='blue-text'>
              <h2>ABOUT US</h2>
              <div className={`wysiwyg-inject fs-85 animated`} dangerouslySetInnerHTML={{ __html: wysiwyg?.[0] }} />
            </div>
          </Intro>
        </div>
        <div ref={refFormInView} className='form c0 padd-2'>
          <div className='form-control'>
            <Intro visible={ioFormInView} in={{ fade: 700 }} delayIn={600} mounted={true} stay={true} className="of-visible">
              <FormDetail fields={"Request Info"} />
            </Intro>
          </div>
        </div>

      </div>
      <div ref={refExploreInView} className='span-7 span-12-mobile'>
        <Intro visible={ioExploreInView} in={{ fade: 500 }} delayIn={500} mounted={true} stay={true} className="span-7 span-12-tablet c5">
          <div style={{ zIndex: "100" }} className='padd-2'>
            <h2>Our Programs</h2>

            <Intro visible={ioExploreInView} in={{ fadeRight: 500 }} delayIn={750} mounted={true} stay={true} className="">
              <div className='single-school padd padd-left-off'>
                <div className='content padd padd-top-off padd-left-off'>
                  <h4>School of Education</h4>
                  <p className='fs-85'>Established in 2006 offering diploma programs for the Florida Teacher Certification. The School of Education is currently offering the Master of Educational Administration program.</p>
                  <div className="links-container fs-85">
                    <Link to="/archives/academic/master-of-education-in-educational-administration" className='button'>MASTER OF EDUCATION IN EDUCATIONAL ADMINISTRATION<span className="btn-arrow"></span></Link >
                  </div>
                </div>
                <div className='img'>
                  <div className='aspect-ratio'>
                    <Img fluid={image?.[0]}
                      imgStyle={{ objectFit: 'cover' }}
                      objectPosition='50% 50%'
                      className='Hero Text'
                      alt='School of Education'
                    />
                  </div>
                </div>
              </div>
            </Intro>

            <Intro visible={ioExploreInView} in={{ fadeRight: 500 }} delayIn={825} mounted={true} stay={true} className="">
              <div className='single-school padd padd-left-off'>
                <div className='content padd padd-top-off padd-left-off'>
                  <h4>School of Business</h4>
                  <p className='fs-85'>Established in 2013, offering diploma and associate degree accounting programs. Currently the School of Business is offering the MBA program.</p>
                  <div className="links-container fs-85">
                    <Link to="/archives/academic/master-of-business-administration" className='button'>MASTER OF BUSINESS ADMINISTRATION<span className="btn-arrow"></span></Link >
                  </div>
                </div>
                <div className='img'>
                  <div className='aspect-ratio'>
                    <Img fluid={image?.[1]}
                      imgStyle={{ objectFit: 'cover' }}
                      objectPosition='50% 50%'
                      className='Hero Text'
                      alt='School of Business'
                    />
                  </div>
                </div>
              </div>
            </Intro>

            <Intro visible={ioExploreInView} in={{ fadeRight: 500 }} delayIn={950} mounted={true} stay={true} className="">
              <div className='single-school padd padd-left-off'>
                <div className='content padd padd-top-off padd-left-off'>
                  <h4>School of NUrsing</h4>
                  <p className='fs-85'>Established in 2012, includes undergraduate programs for Associate and Bachelor’s degrees. Eligible graduates of these programs are prepared to take the National Council Licensure Examination (NCLEX-RN) and become licensed Registered Nurses.</p>
                  <div className="links-container fs-85">
                    <Link to="/archives/academic/bachelor-of-science-in-nursing-rn-to-bsn" className='button'>BACHELOR OF SCIENCE IN NURSING (RN TO BSN)<span className="btn-arrow"></span></Link >
                    <Link to="/archives/academic/master-of-science-in-nursing-direct-entry" className='button'>MASTER OF SCIENCE IN NURSING DIRECT ENTRY<span className="btn-arrow"></span></Link >
                    <Link to="/archives/academic/family-nurse-practitioner-post-master-certificate-fnp-pmc" className='button'>FAMILY NURSE PRACTITIONER POST-MASTER CERTIFICATE<span className="btn-arrow"></span></Link >
                    <Link to="/archives/academic/bachelor-of-science-degree-in-nursing-bsn-2" className='button'>BACHELOR OF SCIENCE DEGREE IN NURSING (BSN)<span className="btn-arrow"></span></Link >
                    <Link to="/archives/academic/associate-of-science-degree-in-nursing2" className='button'>ASSOCIATE OF SCIENCE DEGREE IN NURSING<span className="btn-arrow"></span></Link >
                    <Link to="/archives/academic/family-nurse-practitioner2" className='button'>MASTER OF SCIENCE IN NURSING FAMILY NURSE PRACTITIONER<span className="btn-arrow"></span></Link >
                  </div>
                </div>
                <div className='img'>
                  <div className='aspect-ratio'>
                    <Img fluid={image?.[2]}
                      imgStyle={{ objectFit: 'cover' }}
                      objectPosition='50% 50%'
                      className='Hero Text'
                      alt='School of NUrsing'
                    />
                  </div>
                </div>
              </div>
            </Intro>

          </div>
        </Intro>

      </div>





    </div>
  )
}

export default CustomBlock