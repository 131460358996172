import React, { useState } from 'react'
import './style.scss'

import { useForm, Controller } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby, faUpload } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'


function CF7Job(props) {

	
	const { register, handleSubmit, errors, reset, control } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)

	const [uploadLabel, setUploadLabel] = useState('ATTACH RESUME...')

	const onUploadChange = (e) => {
		setUploadLabel('Upload: ' + e?.currentTarget?.files[0]?.name)
	}

	// Form activation // thanks
	const [isActive, setActive] = useState(false)
	const [isSent, setSent] = useState(false)

	const customStyles = {
		// For the options
		option: (styles, { isDisabled}) => {
		  return {
			...styles,
			color: '#142D48',
		  };
		},
	};

	const typeofeducation = [
		{
			value: 'Highest level of Education',
			label: 'Highest level of Education',
			isdisabled: true
		},
		{
			value: 'High School Diploma',
			label: 'High School Diploma',
			custom: 'subitem'
		},
		{
			value: 'Some College',
			label: 'Some College',
			custom: 'subitem'
		},
		{
			value: 'Undergraduate Degree',
			label: 'Undergraduate Degree',
			custom: 'subitem'
		},
		{
			value: 'Graduate Degree',
			label: 'Graduate Degree',
			custom: 'subitem'
		}
	]

	const typeofworktime = [
		{
			value: 'Full Time',
			label: 'Full Time',
		},
		{
			value: 'Part Time',
			label: 'Part Time',
		},
	]

	const typeofdepartment= [
		{
			value: 'Administration',
			label: 'Administration',
		},
		{
			value: 'Faculty',
			label: 'Faculty',
			isdisabled: true
		},
		{
			value: 'School of Nursing',
			label: 'School of Nursing',
			custom: "subitem"

		},
		{
			value: 'School of Business',
			label: 'School of Business',
			custom: "subitem"

		},
		{
			value: 'School of Education',
			label: 'School of Education',
			custom: "subitem"

		},
		{
			value: 'School of General Studies',
			label: 'School of General Studies',
			custom: "subitem"
		},
	]

	const formatOptionLabel = ({ value, label, custom }) => {
		if(custom === 'subitem')
			return (
			<div style={{ display: "flex" }}>
				<div className="input-margin-left" style={{ marginLeft: "20px"}}>
					{label}
				</div>
			</div>
			)
		else 
			return(
			<div style={{ display: "flex" }}>
				<div>
					{label}
				</div>
			</div>
			)
	}


	const onClickFillThisForm = (e) => {
		setActive(status => !status)
	}

	const onSubmit = (form, e) => {
		e.preventDefault()

		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
		data.append('your-fname', form.fname)
    data.append('your-lname', form.lname)
		data.append('your-email', form.email)
		data.append('your-phone', form.phone)
		data.append('your-typeofworktime', form.typeofworktime?.value)
		data.append('your-typeofdepartment', form.typeofdepartment?.value)
		data.append('your-typeofeducation', form.typeofeducation?.value)
		data.append('your-resume', form.resume[0])
		
		setUploadLabel(form.resume[0].name)

    const url = 'https://cms.mru.edu/wp-json/contact-form-7/v1/contact-forms/2304/feedback'
		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
					console.log('status', response.data.status)
					console.log('response', response.data)
					if(response.data.message) {
					 setMessage(response.data.message)
					}
					setLoading(false)
					if(response.data.status === 'mail_sent') {
						setSent(true)
						setActive(false)
						reset()
					}
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}

	return (
		<>
			<div className="badge">
				<div className={`hi ${!isActive && !isSent ? 'active' : ''}`}></div>
				<div className={`thanks ${isSent && !isActive ? 'active' : ''}`}></div>
			</div>

			<div className="form-cut-off">
				<form
					name="contact"
					method="post"
          id="job-form"
					action="/thanks/"
					onSubmit={handleSubmit(onSubmit)}
					className={`grid-12 uppercase catering border-type-4 ${isActive ? 'active' : ''}`}
				>

					<div className={`span-12 grid-12 form-fields-wrap ${isActive ? 'active' : ''}`}>

						<label  className="span-6 font4 animated delay-500">
							<input  type="text" className="text-left" placeholder="FIRST NAME" name="fname" ref={register({required: false, maxLength: 80, message: "error message"})} />
							{errors.name && <p className="small margin-off">your first name is required.</p>}
						</label>
                        <label className="span-6 font4 animated delay-500">
							<input type="text" className="text-left" placeholder="LAST NAME" name="lname" ref={register({required: false, maxLength: 80, message: "error message"})} />
							{errors.name && <p className="small margin-off">your last name is required.</p>}
						</label>
						<label className="span-6 font4 animated delay-500">
							<input type="text" className="text-left" placeholder="EMAIL" name="email" ref={register({required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
							{errors.email && <p className="small margin-off">a correct email is required.</p>}
						</label>
						<label className="span-6 font4 animated delay-500">
							<input type="text" className="text-left" placeholder="PHONE" name="phone" ref={register({required: false, maxLength: 80, message: "error message"})} />
						</label>
						<label className="elevate-z z-index-2000 span-6 span-12-mobile animated delay-500">
								<Controller
									as={Select}
									name="typeofworktime"
									control={control}
									isClearable
									className="font4" 
									placeholder="FULL OR PART TIME"
									options={typeofworktime.map(val => (
										{ value: val.value, label: val.label }
									))}
									theme={theme => ({
										...theme,
										borderRadius: 0,
										colors: {
											...theme.colors,
											primary: '#CFA052',
											neutral70: 'hsl(0, 0%, 30%)',
											neutral90: 'hsl(0, 0%, 100%)',
										},
									})}
								/>
						</label>
						<label className="elevate-z z-index-1500  span-6 span-12-mobile animated delay-500">
								<Controller
									as={Select}
									name="typeofdepartment"
									control={control}
									isClearable
									isOptionDisabled={(option) => option.isdisabled}
									className="font4" 
									formatOptionLabel={formatOptionLabel}
									placeholder="Department Of Interest"
									classNamePrefix="department"
									options={typeofdepartment.map(val => (
										{ value: val.value, label: val.label, isdisabled: val.isdisabled, custom: val.custom }
									))}
									styles={customStyles}
									theme={theme => ({
										...theme,
										borderRadius: 0,
										colors: {
											...theme.colors,
											primary: '#CFA052',
											neutral70: 'hsl(0, 0%, 30%)',
											neutral90: 'hsl(0, 0%, 100%)',
										},
									})}
								/>
						</label>
						<label className="elevate-z z-index-1000  span-6 span-12-mobile animated delay-500">
								<Controller
									as={Select}
									name="typeofeducation"
									control={control}
									isClearable
									isOptionDisabled={(option) => option.isdisabled}
									className="font4" 
									formatOptionLabel={formatOptionLabel}
									placeholder="EDUCATION"
									classNamePrefix="department"
									options={typeofeducation.map(val => (
										{ value: val.value, label: val.label, isdisabled: val.isdisabled, custom: val.custom }
									))}
									styles={customStyles}
									theme={theme => ({
										...theme,
										borderRadius: 0,
										colors: {
											...theme.colors,
											primary: '#CFA052',
											neutral70: 'hsl(0, 0%, 30%)',
											neutral90: 'hsl(0, 0%, 100%)',
										},
									})}
								/>
						</label>
						<label className="elevate-z  inputfile span-6 span-12-mobile animated delay-500">
							<a className="inputfilelabel btn"><span><FontAwesomeIcon icon={faUpload} />{uploadLabel}</span></a>
							<input type="file" placeholder="Resume" name="resume" ref={register({required: true, message: "error message"})} onChange={(e) => onUploadChange(e)} />
							{errors.resume && <p className="small margin-off">Your resume is required, or the filetype not allowed. Try PDF, DOC, DOCX, under 25Mb</p>}
						</label>
						<label className="inputcheckbox span-12 animated delay-500">
							<div className="checkbox">
								<input type="checkbox" placeholder="Agreement" name="agreement" ref={register({required: true, message: "error message"})}  />
								<span className="checkmark"></span>
							</div>
							<p className="desc">I UNDERSTAND SUBMITTING THIS FORM DOES NOT GUARANTEE AN INVITE TO THE IN-PERSON JOB FAIR ON WEDNESDAY, NOVEMBER 10TH. A CONFIRMATION WITH INVITATION DETAILS VIA EMAIL WILL BE SENT TO THE EMAIL ADDRESS PROVIDED ABOVE IF ELIGIBLE. I CERTIFY THAT THE INFORMATION PROVIDED IS TRUE AND CORRECT.
								{errors.agreement && <p className="small message margin-off">This field is required</p>}
							</p>
						</label>

						<div className="btn-wrap text-center animated delay-500">
							<button type="submit">SUBMIT {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
						</div>

						<div className="response-wrap text-center span-12 animated delay-500">
							<p className="message small">{message}</p>
						</div>
					</div>

				</form>
			</div>
		</>
	)
}

export default CF7Job