import React, {useEffect} from 'react'
import './style.scss'
import _ from 'lodash'



function CustomBlock(props) {

    const navItem = []
    const singleItem = []    
  
    for ( let i = 0; i < props.wysiwygs.length; i++) {

        let anchor = props.anchors[i] 
        let field = props.fields[i] 
        let wysiwyg = props.wysiwygs[i] 

        navItem.push (
            field ? 
                <div key={i} className='side-nav-item nav-item span-12'>
                    <a href={`#${anchor}`}>{field}</a>
                </div>
                :
            null
        )

        singleItem.push(
            wysiwyg ? 
                <div key={i} className="single-item grid-12">
                    <div id={anchor} className="anchor"></div>
        
                    <h2 className='span-12'>{field}</h2>
                
                    <div className="content-box span-12 span-12-mobile">
                        <div className={`wysiwyg-inject animated`} dangerouslySetInnerHTML={{__html: wysiwyg}} />  
                    </div>
                </div>
            :
                null
        )
    }

    useEffect(() => {
        const sections = document.querySelectorAll('.single-item')
        const vh = window.innerHeight
        sections.forEach((node,i) => {
            const height = node.offsetHeight
            if (height > vh) {
                const anchor = node.querySelector('.anchor')
                anchor.classList.add('topper')
            }
        })
    },[])
    
    useEffect(() => {

        function scrollListener() {
            const navLinks = document.querySelectorAll(".side-nav-item a");
            const sections = document.querySelectorAll('.single-item');
    
            navLinks.forEach( (link, index) => {
            
                let section = sections[index]
                let halfWindow = window.scrollY + window.innerHeight/2
                let start = section?.getBoundingClientRect().top + window.pageYOffset 
                let bottom = section?.getBoundingClientRect().top + window.pageYOffset + section?.offsetHeight
        
                if (halfWindow < bottom && halfWindow > start) 
                    link.classList.add("current");
                else 
                    link.classList.remove("current");
            });
        }

        window.addEventListener("scroll", _.throttle(scrollListener,100));
        return () => window.removeEventListener("scroll", scrollListener)

    },[]);

	return (
		<div className="master-wrap grid-12">
            
             <div className="span-7 span-12-mobile content">
               {singleItem}
            </div> 

            <div className="span-5 box">
                {props.fields[0] ?
                  <div className="line"></div>
                :
                  null
                }
                <div className="nav"> 
                    {navItem}
                </div>
            </div>

		</div>
	)
}

export default CustomBlock