import React, { useState } from 'react'
import './style.scss'

import { useForm, Controller } from 'react-hook-form'
import axios from 'axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBaby } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'


function CF7InformationLanding(props) {

	
	const { register, handleSubmit, errors, reset, control } = useForm()
	const [message, setMessage] = useState(false)
	const [isLoading, setLoading] = useState(false)

	// Form activation // thanks
	const [isActive, setActive] = useState(false)
	const [isSent, setSent] = useState(false)

	const shift = [
		{
			value: 'Day',
			label: 'Day',
		},
		{
			value: 'Night',
			label: 'Night',
		},
		{
			value: 'Weekends',
			label: 'Weekends',
		},
	]

	const program = [
		{
			value: 'Associate of Science in Nursing (ASN)',
			label: 'Associate of Science in Nursing (ASN)',
		},
		{
			value: 'Bachelor of Science in Nursing (BSN)',
			label: 'Bachelor of Science in Nursing (BSN)',
		},
		{
			value: 'Bachelor of Science in Nursing (RN to BSN)',
			label: 'Bachelor of Science in Nursing (RN to BSN)',
		},
		{
			value: 'Master of Science in Nursing: Family Nurse Practitioner',
			label: 'Master of Science in Nursing: Family Nurse Practitioner',
		},
		{
			value: 'Master of Science in Nursing: Family Nurse Practitioner (PMC)',
			label: 'Master of Science in Nursing: Family Nurse Practitioner (PMC)',
		},
		{
			value: 'Master of Science in Nursing: Direct Entry',
			label: 'Master of Science in Nursing: Direct Entry',
		},
		{
			value: 'Master of Business Administration (MBA)',
			label: 'Master of Business Administration (MBA)',
		},
		{
			value: 'Master of Education in Educational Administration (MEd)',
			label: 'Master of Education in Educational Administration (MEd)',
		},
		{
			value: 'ESOL / Diploma',
			label: 'ESOL / Diploma',
		},
		{
			value: 'Bachelor of Science in Applied Behavior Analysis',
			label: 'Bachelor of Science in Applied Behavior Analysis',
		},
		{
			value: 'Continuing Educationa',
			label: 'Continuing Education',
		}
	]

	const contacttime = [
		{
			value: 'Afternoon',
			label: 'Afternoon',
		},
		{
			value: 'Anytime',
			label: 'Anytime',
		},
		{
			value: 'Evening',
			label: 'Evening',
		},
		{
			value: 'Morning',
			label: 'Morning',
		}
	]


	const customStyles = {
		// control: (base, state) => ({
		// 	...base,
		// 	boxShadow: "none",
		// }),
		// option: (base, state) => ({
		// 	...base,
		// 	color: state.isSelected ? '#000' : state.isFocused ? '#000' : '#DCBA7B',
		// }),
		// valueContainer: (base) => ({
		// 	...base,
		// 	minHeight: 30,
		// })
	}


	const onSubmit = (form, e) => {
		e.preventDefault()

		setLoading(true)

		// Left is CF7 input name, right is ours
		let data = new FormData()
		data.append('your-fname', form.fname)
        data.append('your-lname', form.lname)
		data.append('your-email', form.email)
		data.append('your-phone', form.phone)
		data.append('your-mobile', form.mobile)
		data.append('your-shift', form.shift?.value)
		data.append('your-program', form.program?.value)
		data.append('your-contacttime', form.contacttime?.value)
		data.append('your-comment', form.comment)
		
		
		    const url = 'https://cms.mru.edu/wp-json/contact-form-7/v1/contact-forms/1183/feedback'
    		const config = { headers: { 'Content-Type': 'multipart/form-data' } }

		axios.post(url, data, config)
			.then(response => {
				console.log(data)
					console.log('status', response.data.status)
					console.log('response', response.data)
					if(response.data.message) {
					 setMessage(response.data.message)
					}
					setLoading(false)
					if(response.data.status === 'mail_sent') {
						setSent(true)
						setActive(false)
						reset()
					}
				}
			)
			.catch(errors => {
					console.log(errors)
				}
			)
	}

	return (
		<>
			<div className="badge">
				<div className={`hi ${!isActive && !isSent ? 'active' : ''}`}></div>
				<div className={`thanks ${isSent && !isActive ? 'active' : ''}`}></div>
			</div>

			<div className="form-cut-off">
				<form
					name="contact"
					method="post"
                    id="information-form"
					action="/thanks/"
					onSubmit={handleSubmit(onSubmit)}
					className={`grid-12 uppercase catering ${isActive ? 'active' : ''}`}
				>

					<div className={`span-12 grid-12 form-fields-wrap gap-3 ${isActive ? 'active' : ''}`}>

						<label  className="span-6 font4 input animated delay-500">
							<input  type="text" className="text-left" placeholder="FIRST NAME" name="fname" ref={register({required: false, maxLength: 80, message: "error message"})} />
							{errors.fname && <p className="small margin-off">your first name is required.</p>}
						</label>
                        <label className="span-6 font4 input animated delay-500">
							<input type="text" className="text-left" placeholder="LAST NAME" name="lname" ref={register({required: false, maxLength: 80, message: "error message"})} />
							{errors.lname && <p className="small margin-off">your last name is required.</p>}
						</label>
						<label className="span-6 font4 input animated delay-500">
							<input type="text" className="text-left" placeholder="EMAIL" name="email" ref={register({required: true, minLength: 6, pattern: /^\S+@\S+$/i})} />
							{errors.email && <p className="small margin-off">a correct email is required.</p>}
						</label>
						<label className="span-6 font4 input animated delay-500">
							<input type="text" className="text-left" placeholder="PHONE NUMBER" name="phone" ref={register({required: false, maxLength: 80, message: "error message"})} />
						</label>
						<label className="span-6 font4 input animated delay-500">
							<input type="text" className="text-left" placeholder="MOBILE NUMBER" name="mobile" ref={register({required: false, maxLength: 80, message: "error message"})} />
						</label>
						<p className="span-12 span-12-mobile font4 question">Select Shift</p>
						<label className="elevate-z z-150 span-12 span-12-mobile animated delay-500">
								<Controller
									as={Select}
									name="shift"
									control={control}
									isClearable
									className="font4" 
									placeholder="---"
									//onChange={event => handleOptionChangeSelect(index, event)}
									options={shift.map(val => (
										{ value: val.value, label: val.label }
									))}
									styles={customStyles}
									theme={theme => ({
										...theme,
										borderRadius: 0,
										colors: {
											...theme.colors,
											primary: '#CFA052',
											neutral70: 'hsl(0, 0%, 30%)',
											neutral90: 'hsl(0, 0%, 100%)',
										},
									})}
								/>
						</label>
                        <p className="span-12 span-12-mobile font4 question">Select Program</p>
						<label className="elevate-z z-100 span-12 span-12-mobile animated delay-500">
								<Controller
									as={Select}
									name="program"
									control={control}
									isClearable
									className="font4" 
									placeholder="---"
									//onChange={event => handleOptionChangeSelect(index, event)}
									options={program.map(val => (
										{ value: val.value, label: val.label }
									))}
									styles={customStyles}
									theme={theme => ({
										...theme,
										borderRadius: 0,
										colors: {
											...theme.colors,
											primary: '#CFA052',
											neutral70: 'hsl(0, 0%, 30%)',
											neutral90: 'hsl(0, 0%, 100%)',
										},
									})}
								/>
						</label>
						<p className="span-12 span-12-mobile font4 question">Best Time To Contact</p>
						<label className="elevate-z z-50 span-12 span-12-mobile animated delay-500">
								<Controller
									as={Select}
									name="contacttime"
									control={control}
									isClearable
									className="font4" 
									placeholder="---"
									//onChange={event => handleOptionChangeSelect(index, event)}
									options={contacttime.map(val => (
										{ value: val.value, label: val.label }
									))}
									styles={customStyles}
									theme={theme => ({
										...theme,
										borderRadius: 0,
										colors: {
											...theme.colors,
											primary: '#CFA052',
											neutral70: 'hsl(0, 0%, 30%)',
											neutral90: 'hsl(0, 0%, 100%)',
										},
									})}
								/>
						</label>
						<label className="span-12 font4 animated delay-500">
							<textarea  rows="5" className="text-left" placeholder="COMMENTS" name="comment" ref={register({required: false, maxLength: 80, message: "error message"})} />
						</label>			
		
						

						<div className="btn-wrap text-center animated delay-500">
							<button type="submit">SUBMIT {isLoading ? <div className="loader-spinner"><FontAwesomeIcon icon={faBaby} /></div> : null}</button>
						</div>

						<div className="response-wrap text-center span-12 animated delay-500">
							<p className="message small">{message}</p>
						</div>
					</div>

				</form>
			</div>
		</>
	)
}

export default CF7InformationLanding