import React from 'react'
import './style.scss'

import CF7Job from '../../../../Form/CF7/Job'

function CustomBlock({ fields: field }) {

	return (
        <>
            <h2 className="title">{field}</h2>
            <p>Fill out the form below and submit your application to join the MRU team!</p>
            <CF7Job />
        </>
	)
}

export default CustomBlock