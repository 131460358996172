import React from 'react'
import './style.scss'
import Vimeo from '../../../../Video/HTML'
import Intro from '../../../../../animations/Intro'
import { useInView } from 'react-intersection-observer'

function CustomBlock({ files: file, wysiwygs: wysiwyg }) {

  const [refInView, ioInView] = useInView({ triggerOnce: true })

  return (
    <div ref={refInView}  className='custom-about-home-new c5'>
      <div className='span-33'>
        <div className='intro outline padd-2'>
          <Intro visible={ioInView} in={{ fade: 500 }} delayIn={250} mounted={true} stay={true} className="">
            <h1>ABOUT</h1>
          </Intro>
        </div>
        <Intro visible={ioInView} in={{ fadeLeft: 700 }} delayIn={600} mounted={true} stay={true} className="of-visible">
          <div className='yellowbox padd-3 c3'>
            <div className={`wysiwyg-inject fs-85 animated`} dangerouslySetInnerHTML={{ __html: wysiwyg?.[0] }} />
          </div>
        </Intro>

        <div className='button-container padd-3 padd-top-off'>
          <Intro visible={ioInView} in={{ fade: 700 }} delayIn={700} mounted={true} stay={true} className="">
            <div className={`wysiwyg-inject animated`} dangerouslySetInnerHTML={{ __html: wysiwyg?.[1] }} />
          </Intro>
        </div>
      </div>

      <div className='span-77'>
        <Intro visible={ioInView} in={{ fade: 700 }} delayIn={500} mounted={true} stay={true} className="full-width">
          <div className='aspect-ratio video'>
            <Vimeo autoplay={true} revealonclick={true} buttonText={"<h1></h1>"} file={file?.[0]?.localFile?.publicURL} />
          </div>
        </Intro>
      </div>

    </div>
  )
}

export default CustomBlock