import React, {useEffect, useState} from 'react'
import './style.scss'

import { Parallax, withController } from 'react-scroll-parallax'

import Img from 'gatsby-image'
import ACFOverlay from '../Overlay'
import { Link } from 'gatsby'

function ACFImage(props) {

	// General Settings
	const responsiveBreakpoint = 1024
	const [windowSize, setWindowSize] = useState({width: 0, height: 0})

	// Take care of parallax and image alternative modes for responsive
	useEffect(() => {
		// RAF to update parallax position, it gets lost sometimes otherwise, especially on page changes
		window.requestAnimationFrame(() => {
			props.parallaxController.update()
		})

		// Checking window size, dropping values into state
		function updateSize() {
			setWindowSize({width: window.innerWidth, height: window.innerHeight})
		}
		window.addEventListener('resize', updateSize)
		updateSize()

		// Kill off listener
		return () => window.removeEventListener('resize', updateSize)
	},[props.parallaxController])

	// Image
	const image = props.img
	// Image
	const image_responsive = props.img_responsive
	// Parallax
	let parallax_from = props.parallax_from
	if (!parallax_from) {
		parallax_from = props.parallaxFrom
	}
	let parallax_to = props.parallax_to
	if (!parallax_to) {
		parallax_to = props.parallaxTo
	}

	// Returning Section
  return (
    !props.link ? 
		<>
			{ image ?
				<div className="image-wrap">
					{ parallax_from && parallax_from !== 0 && parallax_to && parallax_to !== 0 ?
						<Parallax className="parallax" y={[parallax_from + '%', parallax_to + '%']} tagOuter="figure">
							{ !image_responsive || windowSize.width > responsiveBreakpoint ?
								<>
									{image.localFile ?
										<Img fluid={image.localFile.childImageSharp.fluid}
											imgStyle={{objectFit: 'cover'}}
											objectPosition='50% 50%'
											className='image-main'
										/>
									: image.srcSet ?
										<img srcSet={image.srcSet} alt={image.alt} className="image-main img-cover"/>
									: null}
								</>
							: null }
							{ image_responsive && windowSize.width < responsiveBreakpoint ?
								<>
									{image_responsive.localFile ?
										<Img fluid={image_responsive.localFile.childImageSharp.fluid}
											imgStyle={{objectFit: 'cover'}}
											objectPosition='50% 50%'
											className='image-responsive'
										/>
									: image_responsive.srcSet ?
										<img srcSet={image_responsive.srcSet} alt={image_responsive.alt} className="image-responsive img-cover"/>
									: null}
								</>
							: null }
						</Parallax>
					:
						<>
							{ !image_responsive || windowSize.width > responsiveBreakpoint ?
								<>
									{image.localFile ?
										<Img fluid={image.localFile.childImageSharp.fluid}
											imgStyle={{objectFit: 'cover'}}
											objectPosition='50% 50%'
											className='image-main'
										/>
									: image.srcSet ?
										<img srcSet={image.srcSet} alt={image.alt} className="image-main img-cover"/>
									: null}
								</>
							: null }
							{ image_responsive && windowSize.width < responsiveBreakpoint ?
								<>
									{image_responsive.localFile ?
										<Img fluid={image_responsive.localFile.childImageSharp.fluid}
											imgStyle={{objectFit: 'cover'}}
											objectPosition='50% 50%'
											className='image-responsive'
										/>
									: image_responsive.srcSet ?
										<img srcSet={image_responsive.srcSet} alt={image_responsive.alt} className="image-responsive img-cover"/>
									: null}
								</>
							: null }
						</>
					}
					<ACFOverlay {...props} />
				</div>
			: null }
		</>
    : 
    <Link to={props.link}>
    { image ?
      <div className="image-wrap">
        { parallax_from && parallax_from !== 0 && parallax_to && parallax_to !== 0 ?
          <Parallax className="parallax" y={[parallax_from + '%', parallax_to + '%']} tagOuter="figure">
            { !image_responsive || windowSize.width > responsiveBreakpoint ?
              <>
                {image.localFile ?
                  <Img fluid={image.localFile.childImageSharp.fluid}
                    imgStyle={{objectFit: 'cover'}}
                    objectPosition='50% 50%'
                    className='image-main'
                  />
                : image.srcSet ?
                  <img srcSet={image.srcSet} alt={image.alt} className="image-main img-cover"/>
                : null}
              </>
            : null }
            { image_responsive && windowSize.width < responsiveBreakpoint ?
              <>
                {image_responsive.localFile ?
                  <Img fluid={image_responsive.localFile.childImageSharp.fluid}
                    imgStyle={{objectFit: 'cover'}}
                    objectPosition='50% 50%'
                    className='image-responsive'
                  />
                : image_responsive.srcSet ?
                  <img srcSet={image_responsive.srcSet} alt={image_responsive.alt} className="image-responsive img-cover"/>
                : null}
              </>
            : null }
          </Parallax>
        :
          <>
            { !image_responsive || windowSize.width > responsiveBreakpoint ?
              <>
                {image.localFile ?
                  <Img fluid={image.localFile.childImageSharp.fluid}
                    imgStyle={{objectFit: 'cover'}}
                    objectPosition='50% 50%'
                    className='image-main'
                  />
                : image.srcSet ?
                  <img srcSet={image.srcSet} alt={image.alt} className="image-main img-cover"/>
                : null}
              </>
            : null }
            { image_responsive && windowSize.width < responsiveBreakpoint ?
              <>
                {image_responsive.localFile ?
                  <Img fluid={image_responsive.localFile.childImageSharp.fluid}
                    imgStyle={{objectFit: 'cover'}}
                    objectPosition='50% 50%'
                    className='image-responsive'
                  />
                : image_responsive.srcSet ?
                  <img srcSet={image_responsive.srcSet} alt={image_responsive.alt} className="image-responsive img-cover"/>
                : null}
              </>
            : null }
          </>
        }
        <ACFOverlay {...props} />
      </div>
    : null }
  </Link>
  )
}

export default withController(ACFImage)