import React from 'react'
import './style.scss'
import Intro from '../../../../../animations/Intro'
import { useInView } from 'react-intersection-observer'
import Img from "gatsby-image"

function CustomBlock({ images: image }) {

  const [refInView, ioInView] = useInView({ triggerOnce: true })

  return (
    <div ref={refInView} className='custom-bar-stamp c3'>
      <div className='content'>
        <h2 className='h1'>25 years of providing <br/> excellence in education</h2>
      </div>
      <div className='img'>
        <div className='aspect-ratio'>
          <Img fluid={image?.[0]}
            imgStyle={{ objectFit: 'cover' }}
            objectPosition='50% 50%'
            className='Hero Text'
            alt='School of Education'
          />
        </div>
      </div>
    </div>
  )
}

export default CustomBlock