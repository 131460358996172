import React from 'react'
import './style.scss'

import Map from '../../../../Map/GMap'

function CustomBlock({images: img, files: file, fields: field, wysiwygs: wysiwyg, maps: map}) {

	return (   
		<div className="container">
				<div className="map-container">
						<Map {...map[0]} />
				</div>
		</div>
	)
}

export default CustomBlock