import React, {useEffect, useState} from 'react'
import './style.scss'

function ACFWYSIWYG(props) {

	// Wysiwyg
	const wysiwyg = props.wysiwyg

	// Collapse Effect

	let isCollapsible = false
	const [isOpen, setIsOpen] = useState(false);

	if(props.classes?.includes('collapse'))  
		isCollapsible = true

	useEffect(() => {
		let contentWrap = document.querySelectorAll(".collapse .attached-to-wysiwyg .wysiwyg-inject *")
		contentWrap.forEach( (item, index) => {
			if(index > contentWrap.length/2)
				item.classList.add('target')
		})
	}, [isOpen])

	// Returning Section
  return (
		<>
			{ wysiwyg ?
				<div style={props.link ? {pointerEvents: "none"} : null} className={`attached-to-${props.acf_fc_layout}`}>
					<div className={`wysiwyg-inject animated collapse${(isOpen ? '-in' : '')}`} dangerouslySetInnerHTML={{__html: wysiwyg}} />
					{isCollapsible ? 
						<p className="collapse-button" onClick={()=> isOpen ? setIsOpen(false) : setIsOpen(true)}>
							{ !isOpen ? "READ MORE" : "READ LESS" }
						</p> 
					: null}
				</div>
			: null }
		</>
  )
}

export default ACFWYSIWYG