import React from 'react'
import './style.scss'

import CF7Nursing from '../../../../Form/CF7/NursingSchool'

function CustomBlock({ fields: field }) {

  return (
    <>
      <h2>{field}</h2>
      <CF7Nursing />
    </>
  )
}

export default CustomBlock